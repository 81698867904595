.RichEditor-root {
  background: #fff;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  cursor: text;
  font-size: 18px;
  margin-top: 10px;

  /* something */
  color: #343434;
  line-height: 20pt;
  letter-spacing: 0.02em;
  display: inline;
}

.RichEditor-editor h3 {
  font-size: 32px;
  font-weight: bolder;
  letter-spacing: 0.001em;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .RichEditor-center {
  text-align-last: center;
  text-align-last: -moz-center;
  text-align-last: -webkit-center;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  cursor: pointer;
  margin-right: 7px;
  margin-top: 7px;
  padding: 7px 14px 7px 14px;
  display: inline-block;

  background-color: rgb(255, 255, 255);
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
  box-shadow: 0 0 6px #9d9d9d53;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: rgb(99, 99, 99);
}

.RichEditor-activeButton {
  color: white;
  background-color: #7abcff;
  /* background-image: linear-gradient(to left, #2ca1fdff, #7eb532ff); */
}
