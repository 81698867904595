/* padding: top left bottom right  noted*/
html {
  overflow-y: scroll;
  font-family: "Inter", sans-serif;
}

body {
  padding-top: 80px;
  font-family: "Inter", sans-serif;
  background-color: #e4ecf1ff;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: solid 0.8px #b4b4b4;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .widget-container {
    display: grid;
    grid-template-columns: 100%;
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto;
  }
  .folder-container .folder {
    height: 90px;
  }
  .dashboardSpecial {
    padding-right: 10px;
    padding-left: 10px;
  }
  .titleDocument {
    font-size: 30px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }

  .ty-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 1rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-bottom: 1rem;
  }

  .ty-table {
    width: 90vw;
  }
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */

  .widget-container {
    display: grid;
    grid-template-columns: 100%;
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto;
  }
  .folder-container .folder {
    height: 90px;
  }
  .dashboardSpecial {
    padding-right: 10px;
    padding-left: 10px;
  }
  .titleDocument {
    font-size: 30px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
  .widget-folder {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .dashboardSpecial {
    padding-right: 30px;
    padding-left: 30px;
  }
  .titleDocument {
    font-size: 50px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .dashboardSpecial {
    padding-right: 30px;
    padding-left: 30px;
  }
  .titleDocument {
    font-size: 50px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }

  .ty-table {
    width: 45vw;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .dashboardSpecial {
    padding-right: 30px;
    padding-left: 30px;
  }
  .titleDocument {
    font-size: 65px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }

  .ty-table {
    width: 100%;
  }
}

@media (min-width: 1150px) {
  /* big landscape tablets, laptops, and desktops */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }
  .folder-container .folder {
    height: 60px;
  }
  .dashboardSpecial {
    padding-right: 50px;
    padding-left: 50px;
  }
  .documentWrapper {
    margin: auto;
  }
  .titleDocument {
    font-size: 65px;
  }
  .documentContainer {
    margin-left: 28vw;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;

    padding: 0px;
  }
  .folder-container .folder {
    height: 60px;
  }
  .dashboardSpecial {
    padding-right: 100px;
    padding-left: 100px;
  }
  .titleDocument {
    font-size: 65px;
  }
  .documentContainer {
    margin-left: 400px;
  }
}
@media (min-width: 1500px) {
  /* hi-res laptops and desktops */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    padding: 0px;
  }
  .folder-container .folder {
    height: 60px;
  }
  .dashboardSpecial {
    padding-right: 100px;
    padding-left: 100px;
  }
  .titleDocument {
    font-size: 65px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1920px) {
  /* hi-res laptops and desktops */

  .widget-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
  .folder-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    padding: 0px;
  }
  .folder-container .folder {
    height: 60px;
  }
  .dashboardSpecial {
    padding-right: 100px;
    padding-left: 100px;
  }
  .titleDocument {
    font-size: 65px;
  }
  .documentContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

.widget-container {
  overflow: hidden;
  grid-gap: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.folder-container {
  grid-gap: 20px;
}
/* .folder-container .folder {
  height: 60px;
} */
.widget-item-picture {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 20px 0px 0px 0px;
}
.widget-item-picture :hover {
  background-color: rgb(194, 234, 255);
}

.main {
  margin-left: 5px; /* Same as the width of the sidenav */
  padding: 0px 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.switch label {
  display: block;
  position: relative;
}
.switch label input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch2 {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
}
.switch2 input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider2:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider2 {
  background-color: #2196f3;
}
input:focus + .slider2 {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider2:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
.thumbnail {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.nomargin {
  margin: 0px;
  padding-top: 0px;
}
.badge-grey {
  color: #000000;
  background-color: #d5e0e7;
}
.badge-lightblue {
  color: "lightblue";
  background-color: #d5e0e7;
}

.landingName {
  font-size: 16px;
  font-weight: 800;
  color: #1e5e96ff;
}

.navbarBrand {
  font-size: 15px;
  font-weight: bolder;
}

.navbarItem {
  font-size: 10px;
}

.topnav {
  z-index: 999;
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 100vw;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;

  padding: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  border: none;
  font-size: 15px;
}

.topnav a.right {
  float: right;

  padding: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  border: none;
  font-size: 15px;
}

.topnav div.right {
  float: right;

  padding: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  border: none;
  font-size: 15px;
}

.navbarDefault {
  background: #ffffff;
}

.navbarLanding {
  background: #ffffff;
}

.carousellGroup {
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
  text-align: center;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.carousell {
  display: flex;
  overflow-x: auto;
  align-items: center;
  text-align: center;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.carousell::-webkit-scrollbar {
  display: none;
}
.carousell button {
  margin-right: 8px;
  margin-left: 8px;
  align-items: center;
  text-align: center;
}
.carousell div {
  margin-right: 5px;
  margin-left: 5px;
  align-items: center;
  text-align: center;
}
.nopadding {
  padding-top: 0px !important;
}

.login_register {
  margin-top: 200px;
  margin-bottom: 300px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 500px;
}

.landing-container {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.landing-grid {
  display: grid;
  grid-template-columns: 50vw 50vw;
}

.landing-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
}
.landing-show {
  width: 68vw;
}

.landing-login {
  margin-left: -80px;
  width: 28vw;
}

.welcome_small {
  font-weight: bolder;
  color: #1e5e96ff;
  font-size: 15px;
}

.form-rounded {
  border-radius: 1rem;
}
.btn-work {
  text-align: center;
}
.btn-half {
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.usernameList button {
  margin-top: 8px;
}

.landingBrand {
  margin-bottom: 80px;
}

/* The container */
.simpleCheck {
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.simpleCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.buttonCheck {
  text-align: center;
  display: inline-block;

  border-radius: 7px 7px 7px 7px;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  color: rgb(86, 86, 86);

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 4px 12px 4px 12px;
  font-size: 12px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.simpleCheck:hover input ~ .buttonCheck {
  color: #ffffff;
  background-color: #2196f3;
}

/* When the checkbox is checked, add a blue background */
.simpleCheck input:checked ~ .buttonCheck {
  color: #ffffff;
  background-color: #1e5e96ff;
}

/* Show the checkmark when checked */
.simpleCheck input:checked ~ .buttonCheck:after {
  color: #ffffff;
  background-color: #1e5e96ff;
}

#checkActive {
  color: #ffffff;
  background-color: #1e5e96ff;
}
#checkNotActive {
  background-color: #ffffff;
  color: rgb(86, 86, 86);
}

/* Style the checkmark/indicator */
.simpleCheck .buttonCheck:after {
  color: #ffffff;
  border-radius: 2rem;
  background-color: #1e5e96ff;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

.toggleContainer {
  border-radius: 2rem;
  background-color: #1e5e96ff;
  display: inline-block;
  padding: 4px 4px 4px 4px;
  margin: 2px 8px 2px 0px;
  text-align: center;
}

.multipleToggle {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  border-radius: 2rem;
  border: none;
  padding: 3px 10px 3px 10px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

#active {
  color: #1e5e96ff;
  background-color: #ffffff;
}

#notActive {
  color: white;
  background-color: transparent;
}

.pictureGrid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 10px;
}
.pictureWraper {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.buttonShadow {
  text-align: center;
  display: inline-block;

  border-radius: 2rem 2rem 2rem 2rem;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: rgb(86, 86, 86);

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 8px 24px 8px 24px;
  font-size: 14px;
  cursor: pointer;
}

.buttonShadowSmall {
  text-align: center;
  display: inline-block;

  border-radius: 7px 7px 7px 7px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: rgb(86, 86, 86);

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 4px 12px 4px 12px;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
}

.header_span {
  display: inline-block;
  font-size: 15px;
  color: #1e5e96ff;
  text-align: left;
  margin-right: 10px;
}

.header_span_small {
  display: inline-block;
  font-size: 12px;
  color: #1e5e96ff;
  text-align: left;
  margin-right: 10px;
}

#dark_when_hover :hover {
  background-color: #1e5e96ff;
  color: rgb(255, 255, 255);
}

.sidebar {
  height: 80vh;
  width: 300px;
  max-width: 80vw;

  left: 2vw;
  z-index: 2;

  overflow-x: hidden;
  overflow-y: auto;

  margin-bottom: 1000px;
  margin-right: 10px;
  padding: 40px 18px 10px 18px;

  /* transition: left 0.4s;
  -webkit-transition: left 0.4s; */

  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;

  background-color: rgb(228, 228, 228);
  border: 1px solid #d8d8d8;
  border-radius: 1rem 1rem 1rem 1rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
}

.scrollSidebar {
  position: fixed;
  top: 450px;
}

.stickySidebar {
  position: fixed;
  top: 100px;
}

.openbtn {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50% 50% 50% 50%;
  background-color: #2ca1fdff;
  color: white;

  font-size: 20px;
  font-weight: bolder;

  border: 1px solid #d8d8d8;
  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: calc((2vw + 300px) / 2);
  z-index: 1;
  /* transition: margin-left 0.4s;
  -webkit-transition: margin-left 0.4s; */
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
}

.scrollOpenbtn {
  position: fixed;
  top: calc(450px - 20px);
}

.stickyOpenbtn {
  position: fixed;
  top: calc(100px - 20px);
}

.titleWraper {
  display: block;
  min-height: 500px;
  position: relative;
  background-color: #0575e6;
  background-image: linear-gradient(
      #ffffff14,
      #ffffff14,
      #ffffff14,
      #ffffffbb,
      #ffffff
    ),
    linear-gradient(to top, #00f260, #0575e6);
}

.titleDocument {
  font-weight: bolder;
  color: #000000;
  line-height: 110%;

  position: absolute;
  top: 70%;
  transform: translateY(-50%);
}

.documentContainer {
  width: 720px;
  max-width: 80vw;
}

#documentEditor {
  min-height: 500px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.buttonTableOfContent {
  text-align: left;
  display: block;
  overflow-x: hidden;

  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: rgb(86, 86, 86);

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 10px 10px 8px 10px;

  font-size: 14px;
  cursor: pointer;
}

.parentTableOfContent {
  background-color: #2196f3;
  color: white;
  margin-top: 20px;
}

.docList {
  margin-top: 7px;
}

#activated {
  color: #2196f3;
}

#not-activated {
  color: rgb(86, 86, 86);
}

.textTableOfContent {
  line-height: 12.5pt;
  letter-spacing: 0.025em;
}

.smallButton {
  display: inline-block;
  border-radius: 2rem 2rem 2rem 2rem;
  background-color: rgba(0, 0, 0, 0.27);
  color: white;
  border: none;

  padding: 2px 9px 2px 9px;
  margin-right: 5px;
  text-align: center;
  font-size: 9px;
  cursor: pointer;
}

#deleteBright {
  background-color: #db1d4fff;
  color: white;
}

#editBright {
  background-color: #1e5e96ff;
  color: white;
}

#purple {
  background-color: rgb(111, 0, 148);
  color: white;
}

#bright_clear {
  background-color: rgb(244, 244, 244);
  color: rgb(0, 0, 0);
}

.buttonSimple {
  border-radius: 2rem 2rem 2rem 2rem;
  background-color: #76b343ff;
  border: none;
  color: white;
  padding: 8px 24px 8px 24px;
  margin: 2px 20px 2px 20px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
  min-width: 160px;
}

.buttonSimpleDarkBlue {
  border-radius: 2rem 0rem 0rem 2rem;
  background-color: #535489ff;
  border: none;
  color: white;
  padding: 5px 24px 5px 24px;
  margin: 0px 0px 2px 20px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
  min-width: 160px;
}

.buttonSimpleDarkGrey {
  border-radius: 2rem 0rem 0rem 2rem;
  background-color: rgb(177, 177, 177);
  border: none;
  color: white;
  padding: 5px 24px 5px 24px;
  margin: 0px 0px 2px 20px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
  min-width: 160px;
}

.buttonSimpleLightGrey {
  border-radius: 2rem 2rem 2rem 2rem;
  background-color: rgb(174, 174, 174);
  border: none;
  color: white;
  padding: 5px 24px 5px 24px;
  margin: 2px 0px 2px 20px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
  min-width: 160px;
}

.buttonSimpleTransparent {
  background-color: none;
  border: none;
  color: rgb(74, 74, 74);
  padding: 5px 24px 5px 35px;
  margin: 2px 0px 2px 0px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
  width: 100vw;
}

.buttonSimpleTransparent2 {
  background-color: none;
  border: none;
  color: rgb(74, 74, 74);
  padding: 5px 24px 5px 24px;
  margin: 2px 0px 2px 0px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  transition: transform 0.2s; /* Animation */
  width: 100vw;
}

.buttonList {
  border-radius: 0rem 0rem 0rem 0rem;
  background-color: #2196f3;
  border: none;
  color: white;
  padding: 5px 24px 5px 35px;
  margin: 2px 0px 2px 0px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
  width: 100vw;
}

.buttonSimple2 {
  border-radius: 2rem;
  background-color: #2196f3;
  border: none;
  color: white;
  padding: 5px 20px 5px 20px;
  margin: 2px 8px 2px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

.button_navbar {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 5px 5px 5px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.button_login {
  border-radius: 0.6rem;
  border: none;
  background: #62cdf2ff;
  color: white;
  padding: 5px 15px 5px 15px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
  font-weight: bold;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
}

.button_login:hover {
  background-color: #1e5e96ff;
}

.button_account {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.button_register {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
}

.welcome {
  font-size: 25px;
  font-weight: bold;
  color: #1e5e96ff;
  text-align: left;
}

.welcomeBig {
  font-weight: bolder;
  color: #1e5e96ff;
  font-size: 30px;
}

#outline_white {
  background-color: #fff;
  color: #373a3cff;
  border: 2px solid #d5d7dbff;
  font-weight: 500;
}

#outline_blue {
  background-color: #e5eefdff;
  color: #1654ccff;
  border: 2px solid #cee1faff;
  font-weight: 500;
}

.button_pill_tight {
  border-radius: 2rem;
  border: none;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.buttonSimple2:hover {
  border-radius: 2rem;
  background-color: #20669b;
  border: none;
  color: white;
  padding: 5px 20px 5px 20px;
  margin: 2px 8px 2px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

.buttonSimpleTight {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 50%;
  color: #393939;
  font-size: 10px;
  font-weight: bolder;
  height: 55px;
  width: 55px;
}

.buttonSimpleTightNormal {
  border-radius: 2rem;
  border: none;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;

  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.buttonClose {
  border-radius: 2rem;
  border: none;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}

#delete {
  background: #ffe9e9;
  color: #9b2020;
}

#deleteBold {
  background: #9b2020;
  color: #ffe9e9;
  padding: 0px 10px 0px 10px;
  font-size: 10px;
}

#edit {
  background: #e9f9ff;
  color: #20669b;
}

#selected {
  background-color: #0575e6;
  background-image: linear-gradient(to right, #00de59, #0166cb);
  color: #ffffff;
}

#dark_blue {
  background-color: #1e5e96ff;
  color: #ffffff;
}

#comfort {
  background: #e9f9ff;
  color: #20669b;
  margin-top: 10px;
  margin-right: 10px;
}

.buttonSold {
  border-radius: 2rem;
  border: none;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}

.buttonOutlineSpecial {
  border-radius: 2rem;
  background: #2ca1fdff;
  color: white;
  padding: 5px 10px 5px 10px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s;
}

#maxwidth {
  max-width: 150px;
  word-wrap: break-word;
  padding: 10px 10px 10px 10px;
}

.buttonSimpleRed {
  border-radius: 2rem;
  background-color: rgb(239, 55, 98);
  border: none;
  color: white;
  padding: 3px 10px 3px 10px;
  margin: 2px 8px 2px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform 0.2s; /* Animation */
}

.sidebarChart {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #dededeff;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 70px 0px 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.sidebarChart #listcontent {
  padding: 0px 0px 0px 0px;
  transition: 0.3s;
}
.sidebarChart div:hover {
  color: #262626;
}
#mainChart {
  transition: margin-top 0.5s;
  padding: 0px 0px 0px 0px;
}
#content {
  transition: margin-top 0.5s;
  padding: 0px 0px 0px 0px;
}
.openbtnChart {
  cursor: pointer;
  height: 38px;
  width: 150px;
  border-radius: 0 0 38px 38px;
  background-color: #2196f3;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: auto;
  left: 50vw;
  z-index: 1;
  transition: 1s;
}

#main {
  transition: margin-left 0.5s;
  padding: 0px 0px 0px 0px;
}
#content {
  transition: margin-left 0.5s;
  padding: 0px 10px 0px 40px;
}

/* basic bulet */
.lingkaran {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #76b343ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cursor {
  margin: auto;
  position: relative;
}
.cursor i {
  left: 50%;
  top: 30%;
  position: absolute;
  width: 2px;
  height: 30px;
  background-color: #42ffd4ff;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
}

.cursor textarea:focus + i,
.cursor input:focus + i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.login-container {
  padding: "100px 100px 100px 100px";
}
/* .usernameLogin {
  margin: auto;
  position: relative;
}
.usernameLogin i {
  left: 10%;
  top: 10%;
  position: absolute;
  width: 35px;
  height: 35px;
  background: url("./img/username_outline.svg") no-repeat left bottom/ cover;
} */

/* .passwordLogin {
  margin: auto;
  position: relative;
}
.passwordLogin i {
  left: 10%;
  top: 10%;
  position: absolute;
  width: 35px;
  height: 35px;
  background: url("./img/password_outline.svg") no-repeat left bottom/ 35px;
} */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cecece;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cecece;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cecece;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  overflow: hidden;
  box-sizing: border-box;
  background-color: transparent;
  margin: auto;
  text-align: left;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

textarea:focus,
input:focus {
  outline: none;
}
*:focus {
  outline: none;
}

#landingLogo {
  transition: 0.8s;
}

#landingName {
  transition: 0.8s;
}

.specialItem {
  background-color: rgb(255, 255, 255);
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 15px 15px 15px 15px;
  margin-right: 10px;
  text-align: left;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #5e5e5e;
}
.specialItem.hide {
  box-shadow: none;
  border: none;
}

.specialItemSelected {
  background-color: rgb(219, 244, 255);
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #00d0ff53;
  -webkit-box-shadow: 0 0 6px #00d0ff53;
  -moz-box-shadow: 0 0 6px #00d0ff53;

  padding: 15px 15px 15px 15px;
  margin-right: 10px;
  text-align: left;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #5e5e5e;
}

.specialItemUser {
  width: 300px;
  height: 600px;
  min-width: 300px;
  max-width: 80vw;
  max-height: 100vh;
  background-color: #0575e6;
  background-image: linear-gradient(to bottom, #00f260, #0575e6);
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  /* padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 0px; */
  margin-top: 10px;
  text-align: left;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #5e5e5e;
}

.specialItemGroup {
  width: 300px;
  height: 600px;
  min-width: 300px;
  max-width: 80vw;
  max-height: 100vh;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
  padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 10px;
  text-align: left;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #5e5e5e;
}

.check_update {
  color: #ffffff;
  background: #facb41ff;
  background-image: linear-gradient(to left, #fadd86ff, #facb41ff);
  border-radius: 10px;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 15px 15px 15px 15px;
  max-width: 70vw;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
}

.uptodate {
  color: #ffffff;
  background: #74dafdff;
  background-image: linear-gradient(to left, #9be6ffff, #62cdf2ff);
  border-radius: 10px;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 15px 15px 15px 15px;
  max-width: 70vw;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
}

.updateAvailable {
  color: #ffffff;
  background: #f56e76ff;
  background-image: linear-gradient(to left, #f88ba4ff, #f66c72ff);
  border-radius: 10px;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 15px 15px 15px 15px;
  max-width: 70vw;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
}

.network_error {
  color: #ffffff;
  background: #fa8ba5ff;
  background-image: linear-gradient(to left, #fa8ba5ff, #e46382ff);
  border-radius: 10px;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 15px 15px 15px 15px;
  max-width: 70vw;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
}

.check_update_mobile {
  color: #ffffff;
  background: #facb41ff;
  background-image: linear-gradient(to left, #fadd86ff, #facb41ff);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  margin-top: 15px;
}

.uptodate_mobile {
  color: #ffffff;
  background: #74dafdff;
  background-image: linear-gradient(to left, #9be6ffff, #62cdf2ff);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  margin-top: 15px;
}

.updateAvailable_mobile {
  color: #ffffff;
  background: #f56e76ff;
  background-image: linear-gradient(to left, #f88ba4ff, #f66c72ff);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  margin-top: 15px;
}

.network_error_mobile {
  color: #ffffff;
  background: #fa8ba5ff;
  background-image: linear-gradient(to left, #fa8ba5ff, #e46382ff);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  margin-top: 15px;
}

.events {
  color: #ffffff;
  background-color: #0575e6;
  background-image: linear-gradient(to left, #00f260, #0575e6);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  margin-top: 15px;
  animation: scale_animate 0.65s alternate infinite linear;
}

.events:hover {
  color: #ffffff;
}

@keyframes scale_animate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.13);
  }
}

.events_static {
  color: #ffffff;
  background-color: #0575e6;
  background-image: linear-gradient(to left, #00f260, #0575e6);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  margin-top: 15px;
}

.events_static:hover {
  color: #ffffff;
}
.itemListFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemListContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 2.5vh;
}

.itemListContainerRenggang {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 6vh;
}

.itemListItem {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

hr {
  border: 1.5px solid #f4f4f4;
  margin: 10px 0px 10px 0px;
}
.simpleContainer {
  text-align: center;
  font-size: 10px;
  font-weight: bolder;
  color: #2196f3;
  background: #e9f9ff;

  padding-top: 10px;
  padding-bottom: 1px;
  /* margin: auto; */
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  transition: 0.2s; /* Animation */
}
.simpleContainer:hover {
  background: rgb(255, 241, 216);
}
.simpleContainer p {
  color: #9d9d9d;
  padding-top: 5px;
}

.simpleParagraph2 {
  display: block;
  font-size: 10px;
  text-align: center;
  margin: auto;
  color: rgb(77, 77, 77);
  cursor: pointer;
}
.simpleParagraph2 :hover {
  color: rgb(84, 84, 84);
}

.simpleParagraph {
  display: block;
  font-size: 10px;
  text-align: center;
  margin: auto;
  color: #bfbfbf;
  cursor: pointer;
}

.simpleImage {
  display: block;
  margin: auto;
  margin-bottom: 3px;
  margin-top: 10px;
  cursor: pointer;
}

.simpleImage:hover ~ .simpleParagraph {
  color: rgb(55, 55, 55);
}

.centerImage {
  display: block;
  margin: auto;
  padding-bottom: 5px;
}

.textImage {
  display: block;
  margin: auto;
  max-width: 90%;
  max-height: 70vh;
  text-align-last: center;
  text-align-last: -moz-center;
  text-align-last: -webkit-center;
}

.textCaption {
  display: block;
  margin: auto;
}

.fixedItem {
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  margin: auto;
}

.fixedFloatItem {
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.sidebarRight {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ffffff;
  background-image: linear-gradient(
    to left,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    rgb(232, 232, 232)
  );
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  width: 180px;
}
.sidebarRightPic {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 5px;
}

.sidebarRightApp {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 5px;
}

.logoBrand {
  padding: 20px 0px 29px 15px;
}

.folder {
  background-color: rgb(255, 255, 255);
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 6px #9d9d9d53;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;

  color: rgb(99, 99, 99);
  padding: 10px 24px 10px 24px;
  text-align: left;
  display: inline-block;
  min-width: 160px;

  font-weight: bold;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;

  width: 100%;
  height: 60px;
}

.fileContainer {
  cursor: pointer;
}

.fileContainer [type="file"] {
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.buttonContainer {
  display: inline-block;
  margin-right: 7px;
  margin-top: 7px;
  padding: 7px 14px 7px 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
  box-shadow: 0 0 6px #9d9d9d53;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: rgb(99, 99, 99);
  height: 40px;
}

.inline-label {
  text-align: right;
  margin-right: 15px;
  float: left;
}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* chart */
.chart {
  grid-area: chart;
}
.table {
  grid-area: table;
}
.bulet_1 {
  grid-area: bulet_1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bulet_2 {
  grid-area: bulet_2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time {
  grid-area: time;
}
.edit {
  grid-area: edit;
}
.delete {
  grid-area: delete;
}
.grid-container-chart-box {
  display: grid;
  grid-template-areas:
    "chart chart table table bulet_1 bulet_2"
    "edit edit edit delete delete delete"
    "time time time time time time";
  grid-gap: 10px;
  background-color: #ffffff;
  padding: 0px;
}
.grid-container-chart-box > div {
  text-align: center;
  padding: 0px 0;
}

.two-container > div {
  text-align: left;
}

/* dashboard */
.var {
  grid-area: var;
}
.sensor_name {
  grid-area: sensor_name;
}
.sensor_unit {
  grid-area: sensor_unit;
}
.delete_button {
  grid-area: delete_button;
}
.grid-container-sensor-input {
  display: grid;
  grid-template-areas: "var sensor_name sensor_name sensor_name sensor_name sensor_unit sensor_unit sensor_unit sensor_unit delete_button";
  grid-gap: 10px;
  background-color: #ffffff;
  padding: 0px;
  align-items: center;
}
.grid-container-sensor-input > div {
  text-align: left;
  padding: 0px 0;
}

/* edit */
.var {
  grid-area: var;
}
.name {
  grid-area: name;
}
.notification {
  grid-area: notification;
}
.min {
  grid-area: min;
}
.max {
  grid-area: max;
}
.footer {
  grid-area: footer;
}
.grid-container-edit-dataset {
  display: grid;
  grid-template-areas:
    "var var"
    "name unit"
    "notification notification"
    "min max"
    "footer footer";
  grid-gap: 10px;
  background-color: #ffffff;
  padding: 0px;
}
.grid-container-edit-dataset > div {
  text-align: center;
  padding: 0px 0;
}

.comment-container {
  display: grid;
  grid-template-columns: 58px auto;
  grid-gap: 10px;
  padding: 0px;
}
.comment-child-container {
  display: grid;
  grid-template-columns: 28px auto;
  grid-gap: 10px;
  padding: 0px;
}

/* fix-container */
.four-container {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  grid-gap: 10px;
}

.three-container {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 10px;
}

.two-container {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-gap: 10px;
}

.container_light {
  border-radius: 7px;
  font-size: 12px;
  padding: 10px;
  border: 1px solid #8a8a8a23;
  box-shadow: 0 0 6px #9d9d9d1d;
  -webkit-box-shadow: 0 0 6px #9d9d9d1d;
  -moz-box-shadow: 0 0 6px #9d9d9d1d;
}

.button_pill {
  text-align: center;
  display: inline-block;
  border-radius: 2rem 2rem 2rem 2rem;
  border: 1.5px solid #d8d8d8;
  padding: 6px 12px 6px 12px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  color: #000;
}

.two_container_list {
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
}

.two_container_button_right {
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 10px;
}

.two_container_button_right_small {
  display: grid;
  grid-template-columns: auto 33px;
  grid-gap: 5px;
}

.two_container_button_left {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 10px;
}

.phone_number {
  display: grid;
  grid-template-columns: 100px auto;
}

.input_icon {
  display: grid;
  grid-template-columns: auto 30px;
  grid-gap: 5px;
  align-items: center;
}
